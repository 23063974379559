




















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PayloadState } from '@/types/types';

@Component({
  name: 'DeleteDialog',
})
export default class DeleteDialog extends Vue {
  @Prop({ required: true, default: false }) state!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ required: true }) message!: string;
  @Prop({ default: 'Are you sure ? ' }) title!: string;
  @Prop({ default: require('@/assets/images/delete.png') }) image!: string;
  @Prop({ default: 'Delete' }) actionText!: string;

  @Emit('actions')
  close(state: PayloadState): PayloadState {
    return state;
  }

  @Emit('delete')
  action(): { loading: PayloadState } {
    return {
      loading: {
        state: false,
        idx: 'delete',
      },
    };
  }
}
